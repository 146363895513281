// [ Utilities ]
@import 'utils/reset.sass';
@import 'utils/colors.sass';
@import 'utils/mixins.sass';
@import 'utils/layout.sass';
@import 'utils/fonts.scss';
@import 'utils/mixins.sass';
@import 'utils/buttons.sass';


// [ Vendor ]
@import 'vendor/notyf.scss';

//
$mobile: 550px;
$ipad: 1100px;


// [ Global styles ]
html {
  -webkit-font-smoothing: antialiased;
  background: #0B131D;
  cursor: default;
  min-width: 320px;
  color: #fff;
  font-size: 16px;
  line-height: normal;
  font-weight: normal; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &.is-menu-open, &.is-form-open {
    overflow: hidden;
    -webkit-overflow-scrolling: touch; } }

.background {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0.25;

  div {
    width: 110%;
    height: 100%;
    @include image('background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; } }

body, button, input, textarea {
  font-family: 'Gotham Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 1px; }

h1 {
  font-family: 'BrandonGrotesque', sans-serif;
  font-size: 60px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  line-height: 0.8em;

  @media screen and (max-width: 100px) {
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5px; }

  @media screen and (max-width: $mobile) {
    font-size: 54px; } }

a {
  text-decoration: none;
  transition: all ease 0.3s;
  color: inherit;

  &:hover {
    text-decoration: none; }

  &:focus {
    outline: 0 !important; } }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 2; }

main {
  flex: 1;
  width: 1140px;
  padding: 0 20px;
  margin: 0 auto;

  @media screen and (max-width: 1140px) {
    width: 100%; }

  @media screen and (min-height: 800px) {
    display: flex;
    align-items: center;
    margin-top: -50px; } }

input::placeholder {
  color: #9e9e9e !important; }

.is-hidden-dev {
  display: none !important; }

.error input {
  color: red; }

.show-mobile {
  display: none;

  @media screen and (max-width: 500px) {
    display: block; } }

.centered {
  text-align: center; }


// -- screens

.screen {
  position: relative;
  z-index: 100;
  width: 100%;
  left: 0;
  margin: 0;
  display: none;
  align-items: center;
  justify-content: flex-end;

  &.is-active {}

  &__content {
    margin-top: 50px;
    color: #ffffff; }

  &__back {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;

    a {
      transition: all ease 0.3s;
      opacity: 0.5;
      display: inline-block;
      // background: rgba(#fff, 0.04)
      // padding: 4px 7px
      border-radius: 2px;

      &:hover {
        opacity: 1; } } } }

@keyframes kenburns {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.05); } }


@import 'components/header.sass';
@import 'components/footer.sass';
@import 'components/welcome.sass';
@import 'components/feedback.sass';
@import 'components/careers.sass';
@import 'components/blog.sass';
