$red-notyf : #ed3d3d;
$green-notyf : #3dc763;

@keyframes fadeinup{
  0%{
    opacity: 0;
    right: -25px;
  }
  30%{
    opacity: 0.8;
    right: -5px;
  }
  100%{
    opacity: 1;
    right: 0;
  }
}

@keyframes fadeoutdown{
  0%{
    opacity: 1;
    bottom: 0;
  }
  30%{
    opacity: 0.2;
    bottom: -3px;
  }
  100%{
    opacity: 0;
    bottom: -15px;
  }
}

@keyframes appear{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 0.5;
  }
  100%{
    opacity: 0.6;
  }
}

@keyframes disappear{
  0%{
    opacity: 0.6;
  }
  30%{
    opacity: 0.1;
  }
  100%{
    opacity: 0;
  }
}

.notyf__icon--alert, .notyf__icon--confirm{
    height: 21px;
    width: 21px;
    background: white;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
}

.notyf__icon--alert{
    &:after, &:before{
      content: "";
      background: $red-notyf;
      display: block;
      position: absolute;
      width: 3px;
      border-radius: 3px;
      left: 9px;
    }

    &:after{
      height: 3px;
      top: 14px;
    }
    &:before{
      height: 8px;
      top: 4px;
    }
}

.notyf__icon--confirm{
    &:after, &:before{
      content: "";
      background: $green-notyf;
      display: block;
      position: absolute;
      width: 3px;
      border-radius: 3px;
    }
    &:after{
      height: 6px;
      transform: rotate(-45deg);
      top: 9px;
      left: 6px;
    }
    &:before{
      height: 11px;
      transform: rotate(45deg);
      top: 5px;
      left: 10px;
    }
}

.notyf__toast{
    display: block;
    overflow: hidden;
    animation: fadeinup 0.3s forwards;
    position: relative;
    padding-right: 13px;
    &.notyf--alert{
      background: $red-notyf;
    }
    &.notyf--confirm{
      background: $green-notyf;
    }
    &.notyf--disappear{
      animation: fadeoutdown 0.3s 1 forwards;
      animation-delay: 0.25s;
      .notyf__message{
        opacity: 1;
        animation: fadeoutdown 0.3s 1 forwards;
        animation-delay: 0.1s;
      }
      .notyf__icon{
        opacity: 1;
        animation: disappear 0.3s 1 forwards;
      }
    }
}

.notyf__wrapper{
    display: table;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 15px;
    border-radius: 3px;
}

.notyf__icon{
    display: table-cell;
    width: 20%;
    text-align: center;
    vertical-align: middle;
    font-size: 1.3em;
    opacity: 0;
    animation: appear 0.5s forwards;
}

.notyf__message{
    display: table-cell;
    width: 80%;
    vertical-align: middle;
    position: relative;
    opacity: 0;
    animation: fadeinup 0.3s forwards;
}

.notyf{
  position: fixed;
  top: 20px;
  right: 30px;
  width: 20%;
  min-width: 300px;
  color: white;
  z-index: 9999;
}

/* Small screens */
@media only screen and (max-width: 736px){
  .notyf__container{
    width: 90%;
    margin: 0 auto;
    display: block;
    right: 0;
    left: 0;
  }
}