.feedback {

  &__form {
    margin-top: 25px;
    margin-bottom: 50px; } }

#careers-check {
  visibility: hidden;
  opacity: 0; }

.form {
  max-width: 850px;
  position: relative;

  @media screen and (max-width: 900px) {
    width: 100%; }

  &__container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      justify-content: flex-start; }

    @media screen and (max-width: 650px) {
      flex-direction: column; } }

  &__section {
    width: 400px;

    @media screen and (max-width: 900px) {
      width: 350px;
      margin-right: 30px; }

    @media screen and (max-width: 650px) {
      width: 100%;
      margin-bottom: 30px; }

    &:last-child {

      @media screen and (max-width: 650px) {
        margin-top: -30px; } } }

  &::before {
    position: absolute;
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJsb2FkZXItMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiICAgICB3aWR0aD0iNDBweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+ICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDMuOTM1LDI1LjE0NWMwLTEwLjMxOC04LjM2NC0xOC42ODMtMTguNjgzLTE4LjY4M2MtMTAuMzE4LDAtMTguNjgzLDguMzY1LTE4LjY4MywxOC42ODNoNC4wNjhjMC04LjA3MSw2LjU0My0xNC42MTUsMTQuNjE1LTE0LjYxNWM4LjA3MiwwLDE0LjYxNSw2LjU0MywxNC42MTUsMTQuNjE1SDQzLjkzNXoiPiAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVUeXBlPSJ4bWwiICAgICAgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiAgICAgIHR5cGU9InJvdGF0ZSIgICAgICBmcm9tPSIwIDI1IDI1IiAgICAgIHRvPSIzNjAgMjUgMjUiICAgICAgZHVyPSIwLjZzIiAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+ICAgIDwvcGF0aD4gIDwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 40px;
    height: 40px;
    z-index: 15;
    left: 150px;
    top: 50%;
    margin: -40px 0 0 0;
    opacity: 0;
    visibility: hidden;

    @media screen and (max-width: 650px) {
      left: 50%;
      margin-left: -40px; } }

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 100;
    transition: all ease 0.3s;
    opacity: 0;
    visibility: hidden;
    top: 0; }

  &.is-sending::after, &.is-sending::before {
    opacity: 1;
    visibility: visible; }

  label.error {
    color: red;
    position: absolute;
    text-transform: uppercase;
    font-size: 9px;
    margin-top: 2px; }

  &__field {
    margin-bottom: 20px;
    position: relative;
    transition: all ease 0.3s;

    .is-sending & {
      opacity: 0.2; }

    &--action {
      margin-top: 10px;
      margin-bottom: 0; }

    &--file {

      label.error {
        margin-top: 19px;
        margin-left: 20px; } } }

  &__label {
    text-transform: uppercase;
    font-size: 13px;
    color: #E8F6FE;
    opacity: 0.7;
    margin-bottom: 10px; }

  &__input {

    input, textarea, select {
      width: 100%;
      height: 46px;
      border-radius: 2px;
      background: rgba(#CBEDFF, 0.15);
      display: block;
      border: 1px solid transparent;
      transition: all ease 0.3s;
      box-shadow: 0 10px 20px rgba(#000, 0.15);
      padding: 0 15px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.4px;
      outline: none;
      appearance: none;

      &:focus {
        border-color: $primary;
        background-color: rgba($primary, 0.1);
        box-shadow: 0 10px 20px rgba(#000, 0.05);

        @media screen and (max-width: 700px) {
          background-color: rgba($primary, 0.15); } }

      &.error {
        background-color: rgba(red, 0.1);
        border-color: red; } }

    &--select {
      position: relative;

      i {
        top: 19px;
        right: 15px;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: #fff transparent transparent transparent;
        opacity: 0.8; } }


    select {
      cursor: pointer;
      text-transform: uppercase;
      padding-right: 45px;
      position: relative;

      &:hover {
        background: rgba(#CBEDFF, 0.25); } }

    input[type="file"] {
      background: none;
      border: none;
      opacity: 0;
      position: absolute;
      z-index: 0; }

    textarea {
      resize: none;
      height: 100px;
      outline: none;
      padding-top: 15px;
      padding-bottom: 15px; }

    .file {
      border-radius: 3px;
      height: 46px;
      line-height: 44px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 280px; } }


  &__radio {
    display: flex;

    &-item {
      margin-right: 10px;

      label {
        font-size: 25px;
        width: 44px;
        height: 44px;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 2px solid transparent;
        background: rgba(#fff, 0.04);
        transition: all ease 0.3s;
        cursor: pointer;
        box-shadow: 0 10px 20px rgba(#000, 0.1);

        &:hover {
          background: rgba(#fff, 0.15);
          box-shadow: 0 10px 20px rgba(#000, 0.1); } }

      input {
        display: none;

        &:checked + label {
          border-color: $primary;
          background-color: rgba(#4A90E2, 0.25); } } } } }




input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  background-color: #000 !important; }

.is-vacancy {
  display: none;

  &.is-showed {
    display: block; } }
