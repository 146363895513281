.btn {
  outline: none;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  transition: all ease 0.3s;
  position: relative;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  height: 38px;
  line-height: 36px;
  border-radius: 100px;
  border: none;
  box-shadow: 0 10px 20px rgba(#000, 0.2);
  color: #fff;
  letter-spacing: 1px;
  padding: 0 30px;
  background: rgba(#fff, 0.075);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;

  &:active {
    top: 1px;

    &.is-disabled {
      top: 0; } }

  &:hover {
    opacity: 0.7; }

  &--white {
    background: #fff;
    border-color: #fff;

    &:hover {
      opacity: 0.85; } }

  &--primary {
    background: $primary;
    min-width: 150px;
    border: 2px solid $primary;

    &.is-active {
      background: rgba($primary, 0.25); } } }




