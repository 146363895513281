.footer {
  padding: 0 50px 30px;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  align-items: center;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    justify-content: center; }

  &__copy {
    color: #fff; }


  &__social {
    display: flex;
    align-items: center;

    @media screen and (max-width: $mobile) {
      margin-bottom: 20px; } }

  &__link {
    color: $whiteblue;
    opacity: 0.5;

    &:hover {
      opacity: 1; }

    &--fb, &--ig {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 20px;
      height: 20px;
      margin-right: 20px;
      opacity: 0.25; }

    &--ig {
      @include image('icon-instagram.svg'); }

    &--fb {
      @include image('icon-facebook.svg');
      margin-right: 40px; } } }
