.wrapper {
  position: relative;
  width: 1334px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1600px) {
    width: 1216px; }

  @media screen and (max-width: 1366px) {
    width: 980px; }

  @media screen and (max-width: 1024px) {
    width: 744px; }

  @media screen and (max-width: 768px) {
    width: 100%; } }
