.careers {

  &__desc {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 20px;

    p {
      margin-bottom: 25px;
      max-width: 480px;

      .is-present & {
        max-width: 650px; }

      &.strong {
        font-weight: bold; } }

    a {
      font-weight: bold;
      display: inline-block;
      padding: 4px 6px 2px;
      background: rgba(#144D6A, 0.7);
      border-radius: 1px;
      line-height: 1em;

      &:hover {
        opacity: 0.6; } } }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px; }

  &__item {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
    width: 380px;
    line-height: 24px;
    padding-top: 30px;
    padding-left: 34px;
    position: relative;
    margin-bottom: 40px;

    @media screen and (max-width: $mobile) {
      width: 100% !important;
      margin-bottom: 30px; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      transform: rotate(45deg);
      width: 47px;
      height: 47px;
      background: $primary-dark;
      border-radius: 5px;
      opacity: 0.4;
      z-index: -1;
      top: 0;
      left: 0;
      box-shadow: 10px 10px 15px rgba(#000, 0.35); }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include image('icon-user.svg');
      width: 16px;
      height: 21px;
      top: 12px;
      left: 16px; }

    hr {
      border: none;
      width: 30px;
      height: 3px;
      background: #fff;
      margin: 10px 0; }

    a {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      padding: 7px 12px;
      background: rgba(#44697D, 0.2);
      border-radius: 2px;
      line-height: 1em;
      margin-top: 10px;
      font-size: 11px;
      box-shadow: 0 5px 10px rgba(#000, 0.2);

      &:hover {
        opacity: 0.6; } } } }
