.welcome {
  min-height: 460px;
  position: relative;
  width: 100%;

  &__list {
    position: relative;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1000px) {
      padding-left: 40px;
      padding-top: 30px;
      margin-bottom: 50px; }

    @media screen and (max-width: $mobile) {
      margin: 30px 10px;
      padding: 0; } }

  &__feature {
    position: absolute;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
    width: 400px;
    line-height: 24px;
    padding-top: 30px;
    padding-left: 34px;

    @media screen and (max-width: 1000px) {
      position: static !important;
      margin-bottom: 30px;
      width: 100%;
      max-width: 600px; }

    @media screen and (max-width: $mobile) {
      position: static !important;
      width: 100% !important;
      margin-bottom: 30px; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      transform: rotate(45deg);
      width: 47px;
      height: 47px;
      background: $primary-dark;
      border-radius: 5px;
      opacity: 0.4;
      z-index: -1;
      top: 0;
      left: 0;
      box-shadow: 10px 10px 15px rgba(#000, 0.35); }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }

    &:nth-child(even) {

      @media screen and (min-width: $mobile) and (max-width: 1000px) {
        margin-left: 100px;
        max-width: 500px; } }


    &:nth-child(1) {
      left: 620px !important;
      top: -55px !important;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 550px !important; } }

    &:nth-child(2) {
      left: 110px !important;
      top: 60px !important;
      width: 550px;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 100px !important;
        width: 500px; } }

    &:nth-child(3) {
      left: 737px !important;
      top: 60px !important;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 637px !important; } }

    &:nth-child(4) {
      left: 0 !important;
      top: 178px !important;
      width: 500px;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 20px !important; } }

    &:nth-child(5) {
      left: 620px !important;
      top: 178px !important;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 550px !important; } }

    &:nth-child(6) {
      left: 110px !important;
      top: 297px !important;
      width: 500px;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 100px !important;
        width: 450px; } }

    &:nth-child(7) {
      left: 737px !important;
      top: 297px !important;

      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        left: 627px !important;
        width: 310px; } }

    // Icons
    &.feature-create::after {
      @include image('icon-create.svg');
      width: 18px;
      height: 21px;
      top: 14px;
      left: 15px; }

    &.feature-ai::after {
      @include image('icon-ai.svg');
      width: 20px;
      height: 20px;
      top: 15px;
      left: 13px; }

    &.feature-boost::after {
      @include image('icon-boost.svg');
      width: 12px;
      height: 21px;
      top: 14px;
      left: 18px; }

    &.feature-rethink::after {
      @include image('icon-rethink.svg');
      width: 25px;
      height: 18px;
      top: 14px;
      left: 12px; }

    &.feature-maximum::after {
      @include image('icon-maximum.svg');
      width: 22px;
      height: 15px;
      top: 16px;
      left: 13px; }

    &.feature-levelup::after {
      @include image('icon-up.svg');
      width: 13px;
      height: 21px;
      top: 14px;
      left: 17px; }

    &.feature-define::after {
      @include image('icon-define.svg');
      width: 18px;
      height: 19px;
      top: 12px;
      left: 14px; } } }
