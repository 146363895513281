.blog {

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px; }

  &__item {
    text-transform: uppercase;
    width: 400px;
    padding-top: 30px;
    padding-left: 34px;
    position: relative;
    margin-bottom: 60px;

    @media screen and (max-width: $mobile) {
      width: 100% !important;
      margin-bottom: 60px; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      transform: rotate(45deg);
      width: 47px;
      height: 47px;
      background: $primary-dark;
      border-radius: 5px;
      opacity: 0.4;
      z-index: -1;
      top: 0;
      left: 0;
      box-shadow: 10px 10px 15px rgba(#000, 0.35); }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include image('icon-blog.svg');
      width: 19px;
      height: 19px;
      top: 13px;
      left: 14px;
      opacity: 0.8; } }

  &__title {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    transition: all ease 0.3s;
    display: block;

    &:hover {
      opacity: 0.6; } }

  &__cover {
    height: 130px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
    max-width: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

  &__desc {
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 15px; }

  &__more {

    a {
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.4px;
      background: rgba(#44697D, 0.2);
      display: inline-block;
      padding: 9px 12px 8px;
      line-height: 1em;
      border-radius: 2px;
      box-shadow: 0 5px 10px rgba(#000, 0.2);

      &:hover {
        opacity: 0.6; } } }

  &__date {
    position: absolute;
    opacity: 0.4;
    font-size: 13px;
    top: -5px;
    left: 50px; }

  &__back {
    margin-bottom: 15px;
    position: relative;
    z-index: 10;

    a {
      text-transform: uppercase;
      text-transform: uppercase;
      font-size: 12px;
      opacity: 0.5;

      &:hover {
        opacity: 1; }

      &::before {
        content: '⇠';
        display: inline-block;
        margin-right: 5px; } } }

  &__content {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 20px;
    max-width: 750px;

    hr {
      margin: 30px 0;
      width: 100%;
      height: 2px;
      background: #fff;
      opacity: 0.3; }

    h1, h2, h3, h4 {
      font-family: 'BrandonGrotesque', sans-serif; }

    h1, h2 {
      font-size: 25px;
      margin-bottom: 20px;
      margin-top: 35px; }

    h3 {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 15px; }

    p {
      margin-bottom: 30px;


      &.strong {
        font-weight: bold; } }

    blockquote {
      padding-left: 20px;
      opacity: 0.8;
      border-left: 5px solid rgba(#fff, 0.2);
      font-style: italic;
      margin-bottom: 30px; }

    a {
      font-weight: bold;
      display: inline-block;
      padding: 4px 6px 2px;
      background: rgba(#144D6A, 0.7);
      border-radius: 1px;
      line-height: 1em;


      &:hover {
        opacity: 0.6; } }

    img {
      max-width: 100%; }

    ul, ol {
      margin-bottom: 30px;
      padding-left: 10px;

      li {
        margin-bottom: 5px; } }

    ol {

      li {
        list-style-type: decimal;
        list-style-position: inside; } }

    ul {

      li {

        &::before {
          content: '•';
          display: inline-block;
          margin-right: 10px; } } } } }
