.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    margin-bottom: 40px; }

  &__logo {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mobile) {
      padding: 0; }

    a {
      display: block;
      width: 130px;
      height: 130px;
      padding: 20px;
      transition: all ease 0.3s;

      @media screen and (max-width: $mobile) {
        width: 110px;
        height: 110px; }

      &:hover {
        opacity: 0.6; }

      img {
        max-width: 100%;
        margin-left: 12px;
        margin-top: 6px;

        @media screen and (max-width: $mobile) {
          margin: 0; } } } }

  &__menu {
    display: flex;
    margin-top: 45px;
    margin-right: 50px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;

    @media screen and (max-width: $mobile) {
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-right: 30px; }

    li {
      margin-left: 55px; } }

  &__link {
    transition: all ease 0.3s;
    position: relative;
    top: 4px;

    &::after {
      content: '';
      display: block;
      height: 2px;
      background: transparent;
      width: 100%;
      margin-top: 2px; }

    &:hover {
      color: $primary; }

    &.is-active {
      color: $primary;
      cursor: default !important;

      &::after {
        background: $primary; } } }

  .btn {

    @media screen and (max-width: $mobile) {
      margin-top: 15px; } } }
