@font-face {
  font-family: 'BrandonGrotesque';
  src: url('fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),  url('fonts/BrandonGrotesque-Bold.otf')  format('opentype'),
	     url('fonts/BrandonGrotesque-Bold.woff') format('woff'), url('fonts/BrandonGrotesque-Bold.ttf')  format('truetype'), url('fonts/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
    src: url("fonts/GothamProLight.eot");
    src: url("fonts/GothamProLight.eot?#iefix")format("embedded-opentype"),
    url("fonts/GothamProLight.woff") format("woff"),
    url("fonts/GothamProLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),  url('fonts/GothamPro-Medium.woff') format('woff'), url('fonts/GothamPro-Medium.ttf')  format('truetype'), url('fonts/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gotham Pro";
    src: url("fonts/GothamProRegular.eot");
    src: url("fonts/GothamProRegular.eot?#iefix")format("embedded-opentype"),
    url("fonts/GothamProRegular.woff") format("woff"),
    url("fonts/GothamProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gotham Pro";
    src: url("fonts/GothamProBold.eot");
    src: url("fonts/GothamProBold.eot?#iefix")format("embedded-opentype"),
    url("fonts/GothamProBold.woff") format("woff"),
    url("fonts/GothamProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Gotham Pro";
    src: url("fonts/GothamProItalic.eot");
    src: url("fonts/GothamProItalic.eot?#iefix")format("embedded-opentype"),
    url("fonts/GothamProItalic.woff") format("woff"),
    url("fonts/GothamProItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "Gotham Pro";
    src: url("fonts/GothamProBoldItalic.eot");
    src: url("fonts/GothamProBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("fonts/GothamProBoldItalic.woff") format("woff"),
    url("fonts/GothamProBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
}